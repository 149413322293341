import {
  $_PAGE_,
  $_GENERIC_LIGHTBOX_,
  _LOCALE_,
  _defaultChannelId,
  memberRatePlanList,
  additionalCorpCodes,
  overview_brandId,
  overview_orsId,
  overview_propertyId,
  property_country_code,
  property_state_code,
  search_redirect_url,
  countryCodeList,
  disableRoomInventory,
  property_new,
  property_renovated,
  newRenovate_startDate,
  newRenovate_enddate,
  newRenovate_year,
  isPriceTag,
  priceTag_startDate,
  priceTag_enddate,
  wrccCorpCodes,
  brand_id,
  wrccRateCodes
} from '../base/vars.js';
import ConfigsUtils from '../base/aem-configs/config-utils.js';

import { BBForm } from '../components/booking-bar.js';
import {
  exists,
  simpleLightbox,
  getElTrueHeight,
  scrollToAnimated,
  ResizeHandler,
  isTabletWidth,
  isMobileWidth,
  isDesktopWidth,
  getWindowHeight
} from '../base/dom-utils.js';
import {
  setCriteria,
  getCriteria,
  getSessionCriteria,
  goToBook,
  goToPackages,
  getSearchOverview,
  setSearchOverview,
  setSessionStorage,
  getSessionStorage,
  removeRoomsRates,
  setRoomsRates,
  getBrand,
  getBrandTier,
  UserHandler,
  isWRCCHolder,
  bookDataBeforeSSOLogin
} from '../base/session-handler.js';
import Analytics from '../base/wyn-analytics-module.js';
import { ModifySession } from './modify.js';
import BookingSummary from '../components/booking-summary.js';
import { lazyLoadImageInViewport } from '../base/lazy-load.js';
import RateDetails from '../components/rate-details.js';
import { handleTitleTag } from '../base/seo.js';
import BNPLUplift from '../components/bnpl-uplift.js';
import {
} from '../base/session-handler.js';
import {
  _isNotNull,
  _isWeakTrue,
  isBNPLEX,
  formatDateForBWSAvailability,
  formatNumber,
  formatTimeShow,
  formatDateForPrinting,
  formatDateForUplift,
  getCurrencyMapping,
  convertToCurrency,
  getSelectedCurencyCode,
  getDateFromDateString,
  getNumDays,
  isBarRate,
  isLoyaltyRP,
  isMemberRate,
  toMoney,
  getQueryParameter,
  callService,
  getServiceUrl,
  getAddress,
  getAddressWithoutProvinceCountry,
  setUNAP,
  EventHandler,
  RetailBannerUtils,
  isAllInPriceRegion,
  CookieHandler,
  enableNewRenovatedPriceTag,
  isNotificationEnable,
  isWRCCRate,
  sortRoomRatePlans,
  isStrikeThroughRateEnable,
  hasPolicyFlag, updateCurrencySelectorCode, currencyRate, isFeesInclude,
  printDollarAmount,
  getUpdatedTPDTypeValue,
  checkSessionForDualBrandImage
} from '../base/utils.js';
import HideProvinceCountryConfig from '../base/aem-configs/hide-Province-Country';
import WRNonParticipatingBrandsConfig from "../base/aem-configs/wr-non-participating-brands-config";
import Login from '../components/login.js';
import RenderFavourite from '../components/render-favourite.js';
import OverviewService from '../pages/overview-service.js';
class RoomsRatesFilter {
  constructor(RoomsRates) {
    this.RoomsRates = RoomsRates;
    this._filterData = {
      bedType: {},
      roomType: {},
      accessibleType: {}
    };
    this._filters = {};
    this.minimumPoint;
    $('input[value=\'price-filter\']').click(() => {
      RoomsRates._isFNS = false;
      this.filterListing(false);

      setCriteria({
        useWRPoints: false
      });
    });
    $('input[value=\'wyndham-rewards-filter\']').click(() => {
      if (getCriteria().rooms > 1) {
        simpleLightbox($('#numOfRooms').data('rewards-error'), () => {
          $('#price-filter').click();
          var $form = $('form.booking-bar-form.mini-booking.clearfix');
          BBForm.bbRoomsGuests.setForm($form);
          BBForm.bbRoomsGuests.open();
          BBForm.bbRoomsGuests.errorRooms(true);
        });
        return;
      }
      RoomsRates._isFNS = true;
      this.filterListing(true);

      setCriteria({
        useWRPoints: true
      });
    });
  }

  populateFilters() {
    // Populate bed type filters
    let bedData = this._filterData.bedType;
    for (let key in bedData) {
      let cloneCheckbox = $('.bed-type-checkbox .clone').clone()
        .removeClass('clone');
      cloneCheckbox.find('label').attr('for', key);
      cloneCheckbox.find('input').attr({
        value: key,
        id: key,
        name: key
      });
      cloneCheckbox.find('.filterName').prepend(key);
      $('.bed-type-checkbox').append(cloneCheckbox);
    }

    let roomData = this._filterData.roomType;
    if (Object.keys(roomData).length !== 1 && ((Object.keys(roomData)[0] !== 'nsmk') || (Object.keys(roomData)[0] !== 'smk'))) {
      $('#roomTypeFilter').parent('.panel').removeClass('hidden');
      for (let key in roomData) {
        let cloneCheckbox = $('.room-type-checkbox .clone').clone()
          .removeClass('clone');
        cloneCheckbox.find('label').attr('for', key);
        let catLabel = 'Unknown Property';
        try {
          if (key === 'smk') {
            catLabel = window.roomTypeFilters.smoking;
          } else if (key === 'nsmk') {
            catLabel = window.roomTypeFilters.nonSmoking;
          }
        } catch (e) {
          catLabel = 'Unknown Property';
        }
        cloneCheckbox.find('input').attr({
          value: key,
          id: key,
          name: catLabel
        });
        cloneCheckbox.find('.filterName').prepend(catLabel);
        $('.room-type-checkbox').append(cloneCheckbox);
      }
    }
    let accessibleData = this._filterData.accessibleType;
    if (Object.keys(accessibleData).length === 1 && ((Object.keys(accessibleData)[0] === 'nonaccessible') || (Object.keys(roomData)[0] === 'accessible'))) {
      $('#accessibleFilter').parent('.panel').hide();
    }
    $('.bed-type-checkbox label').each((i, el) => {
      let numRooms = (bedData[$(el).attr('for')] || 0);
      $(el).find('.filterCount')
        .text('(' + numRooms + ')');
    });
    $('.room-type-checkbox label').each((i, el) => {
      let numRooms = (roomData[$(el).attr('for')] || 0);
      $(el).find('.filterCount')
        .text('(' + numRooms + ')');
    });
    let ele = $('#accessibleFilter label');
    let numRooms = (accessibleData[$(ele).attr('for')] || 0);
    $(ele).find('.filterCount')
      .text('(' + numRooms + ')');

    $('.refine-result-wrapper input[type=\'checkbox\']').on('change', () => {
      this.filterListing(this.RoomsRates._isFNS);
    });
    $('#roomTypeFilter input:checkbox').click(() => {

      window.digitalData.roomsRates.filter.refinementType = 'Room Type';
      $('#roomTypeFilter input:checked').each((i, el) => {
        //Note: This method looks long but is needed to grab the display value and not the filter number.
        if (i < 1) {
          window.digitalData.roomsRates.filter.refinementSelection = $.trim($(el).next()
            .children()
            .clone()
            .children()
            .remove()
            .end()
            .text());
        } else {
          window.digitalData.roomsRates.filter.refinementSelection += ', ' + $.trim($(el).next()
            .children()
            .clone()
            .children()
            .remove()
            .end()
            .text());
        }
        Analytics.satelliteTracker('roomsrates_filter');
      });
    });

    $('#bedTypeFilter input:checkbox').click(() => {

      window.digitalData.roomsRates.filter.refinementType = 'Bed Type';
      $('#bedTypeFilter input:checked').each((index, el) => {
        if (index < 1) {
          window.digitalData.roomsRates.filter.refinementSelection = $.trim($(el).next()
            .children()
            .clone()
            .children()
            .remove()
            .end()
            .text());
        } else {
          window.digitalData.roomsRates.filter.refinementSelection += ', ' + $.trim($(el).next()
            .children()
            .clone()
            .children()
            .remove()
            .end()
            .text());
        }
        Analytics.satelliteTracker('roomsrates_filter');
      });
    });

    $('#accessibleFilter input:checkbox').click(() => {
      window.digitalData.roomsRates.filter.refinementType = 'Accessible Type';
      $('#accessibleFilter input:checked').each((i, el) => {
        //Note: This method looks long but is needed to grab the display value and not the filter number.
        if (i < 1) {
          window.digitalData.roomsRates.filter.refinementSelection = $.trim($(el).next()
            .children()
            .clone()
            .children()
            .remove()
            .end()
            .text());
        } else {
          window.digitalData.roomsRates.filter.refinementSelection += ', ' + $.trim($(el).next()
            .children()
            .clone()
            .children()
            .remove()
            .end()
            .text());
        }
        Analytics.satelliteTracker('roomsrates_filter');
      });
    });

    // Trigger window resize event to reposition elements for desktop
    $(window).trigger('resize');
  }
  filterListing(filterByPoints) {
    let filter = this._filters;
    // All-in-one filtering function to handle filtering by rates, room type, bed type.
    // Avoids issues with show/hide overwriting other filters by removing items from a general list as they get filtered out.
    // Start by getting all non-clone rooms and rates.
    let rooms = $('.room').not('.clone');
    let rates = $('#general-rate-list > .rate').not('.clone');
    let ratessrb = $('#srb-rate-list > .rate').not('.clone');
    // Hide all, re-show the ones left after filtering.
    rooms.hide();
    rates.hide();
    ratessrb.hide();
    // Reset the see more/less btns
    $('.see-less').removeClass('see-less');
    $('.see-more-text').text($('.see-more-text').data('more'));

    if (filterByPoints) {
      // Hide all cash rates.
      rates = rates.not('.cash-rate');
      ratessrb = ratessrb.not('.cash-rate');
      // Show/hide prices and point values.
      $('.pac-rate, .plus-points, .rooms-slide .from-rate .or, .rooms-slide .from-rate .points, .pts-label').show();
      $('.money .cash-rate').hide();
      $('.pricing .pac-rate').css('display', 'inline-flex');

      $('.rooms-slide .room').not('.clone')
        .each((i, el) => {
          let $rm = $(el);
          let nonPoints = $('.points-rate', $rm).length <= 0;

          // Keep room hidden if it isn't a room with a point rate
          if (nonPoints) {
            let roomCode = $rm.attr('room');
            rooms = rooms.filter((i, el) => {
              return $(el).attr('room') !== roomCode;
            });
          }

          $('.rate-list-grid-wrapper').show();
          $('.rate-list-grid-wrapper .rate-list .see-more').hide();
        });

      $('.call-for-points').show();
      $('.call-for-price').hide();

    } else {
      // Hide all points rates.
      rates = rates.not('.points-rate');
      ratessrb = ratessrb.not('.points-rate');
      // Show/hide points and price values.
      $(
        '.pac-rate, .plus-points, .rooms-slide .from-rate .or,  .rooms-slide .from-rate .points, .pts-label, .rooms-slide .from-rate .points .points-before'
      ).hide();
      $('.rooms-slide .money').show();
      $('.pricing .cash-rate').css('display', 'inline-flex');
      // Don't show a room if it doesn't have any cash rates
      rooms = rooms.filter((i, el) => {
        let cashRate = $(el).find('.rate.cash-rate');
        return (cashRate.length === 0) || (cashRate.text().trim() !== '');
      });

      // Button Text layout handled for pac-rate and fns rate price buttons- (Rooms slide)
      $('.rooms-slide .room').not('.clone')
        .each((i, el) => {
          let $rm = $(el);
          let isPac = $('.pac-rate', $rm).length > 0;
          let isFns = $('.from-rate .or, .from-rate .points', $rm).length > 0;
          if (isPac || isFns) {
            $('.show-hide-rates .money .from').css('display', 'block');
            $('.show-hide-rates .money .unit-per-night').css('display', 'inline-block');
          }

          $('.rate-list-grid-wrapper').hide();
        });

      $('.call-for-points').hide();
      $('.call-for-price').show();
    }

    // Remove rooms that don't match the user's selected bed type and smoking preference
    filter.roomCat = $('.bed-type-checkbox input:checkbox:checked').map((i, el) => {
      return el.value;
    })
      .get();
    filter.smoking = $('.room-type-checkbox input:checkbox:checked').map((i, el) => {
      return el.value;
    })
      .get();
    filter.accessible = $('.accessible-type.checkbox input:checkbox:checked').map((i, el) => {
      return el.value;
    })
      .get();
    if (filter.roomCat.length > 0) {
      rooms = rooms.filter((i, el) => {
        return filter.roomCat.indexOf($(el).attr('roomcat')) >= 0;
      });
    }
    if (filter.smoking.length > 0) {
      rooms = rooms.filter((i, el) => {
        let smoking = ($(el).attr('nsmk') === 'true') ? 'nsmk' : 'smk';
        return filter.smoking.indexOf(smoking) >= 0;
      });
    }
    if (filter.accessible.length > 0) {
      rooms = rooms.filter((i, el) => {
        let accessible = ($(el).attr('accessible') === 'true') ? 'accessible' : 'nonaccessible';
        return filter.accessible.indexOf(accessible) >= 0;
      });
    }

    // Show all rooms and rates left after filtering.
    rooms.show();
    rates.show();
    rooms.removeClass('hidden-after-three');
    $('.room-slide-level-see-more-btn .slide-see-moreless').removeClass('lessMore');
    $('.room-slide-level-see-more-btn .slide-see-moreless:first-child').addClass('lessMore');

    if (rooms.length < $('.room').not('.clone').length) {
      $('.rate .see-more').hide();
    } else {
      $('.rate').each((i, el) => {
        if ($(el).find('.room')
          .not('.clone').length > 3) {
          $(el).find('.room')
            .not('.clone')
            .slice(3)
            .hide();
          $(el).find('.see-more')
            .show();
        }
      });
    }

    let rateType = filterByPoints ? '.points-rate' : '.cash-rate';
    
    $('.room').each((i, el) => {
      if ($(el).find('#general-rate-list > .rate' + rateType)
        .not('.clone').length > 3) {
        $(el).find('#general-rate-list > .rate' + rateType)
          .not('.clone')
          .slice(3)
          .hide();
        $(el).find('.see-more')
          .show();
      } else {
        $(el).find('.see-more')
          .hide();
      }

      // in points rate filter hide other srbs like 6,7,8
      if ($(el).find('#general-rate-list > .rate.points-rate')
        .not('.clone').length > 0) {
        $(el).find('#general-rate-list > .rate.points-rate')
          .not('.clone')
          .filter(function() { 
            const rate = $(this).attr('rate');
            return rate === 'SRB6' || rate === 'SRB7' || rate === 'SRB8'; 
          })
          .hide();
      } 

      // hide see more for points rate
      if(filterByPoints){
        $(el).find('#general-rate-list .see-more')
          .hide();
      }
      
      // hide per night authoring for prize filter
      if(!filterByPoints){
        $(el).find('#general-rate-list .per-night-text')
          .hide();
      } else {
        $(el).find('#general-rate-list .per-night-text')
          .show();
      }

      // in points rate hide srb alone and show other srbs like 6,7,8
      if ($(el).find('.rate-list-grid-wrapper #srb-rate-list > .rate.points-rate')
        .not('.clone').length > 0) {

        $(el).find('.rate-list-grid-wrapper #srb-rate-list > .rate.points-rate')
          .not('.clone')
          .filter(function() { 
            const rate = $(this).attr('rate');
            return rate === 'SRB6' || rate === 'SRB7' || rate === 'SRB8'; 
          })
          .show();
          $(el).find('.rate-list-grid-wrapper #srb-rate-list .see-more')
          .hide();
      }

      // if srb 6,7,8 are not available then hide that section
      if ($(el).find('.rate-list-grid-wrapper #srb-rate-list > .rate.points-rate')
        .not('.clone')
        .filter('[rate="SRB6"], [rate="SRB7"], [rate="SRB8"]')
        .length === 0) {
        $(el).find('.rate-list-grid-wrapper').hide();
      }
       
      // styles for ipad
      let hasSrb6, hasSrb7, hasSrb8;

      if ($(el).find('.rate-list-grid-wrapper #srb-rate-list > .rate.points-rate')
        .length > 0){
          hasSrb6 = $(el).find('.rate-list-grid-wrapper #srb-rate-list > li[rate="SRB6"]').length > 0;
          hasSrb7 = $(el).find('.rate-list-grid-wrapper #srb-rate-list > li[rate="SRB7"]').length > 0;
          hasSrb8 = $(el).find('.rate-list-grid-wrapper #srb-rate-list > li[rate="SRB8"]').length > 0;
      }

      const count = [hasSrb6, hasSrb7, hasSrb8].filter(Boolean).length;

      const width = $(window).width();

      if (count === 1) {
        if ($(el).find('.rate-list-grid-wrapper #srb-rate-list').length > 0) {
          $(el).find('.rate-list-grid-wrapper #srb-rate-list').addClass('single-srb');
        }
        
        if (($(el).find('.rate-list-grid-wrapper #srb-rate-list')
          .length > 0) && (width >= 720 && width <= 1199)){
            $(el).find('.rate-list-grid-wrapper #srb-rate-list').css({
              display: "grid",
              "grid-template-columns": "1fr"
            });
        }
      } else if (count >= 2) {
        if (($(el).find('.rate-list-grid-wrapper #srb-rate-list')
          .length > 0) && (width >= 720 && width <= 1199)){
            $(el).find('.rate-list-grid-wrapper #srb-rate-list').css({
              display: "grid",
              "grid-template-columns": "repeat(2, 1fr)"
            });
            $(el).find('.rate-list-grid-wrapper #srb-rate-list .unit-per-night').css({
              "flex-direction": "column",
              "align-items": "start"
            });
        }
        if(($(el).find('.rate-list-grid-wrapper #srb-rate-list')
          .length > 0) && (width >= 1200) && (count === 3)){
            $(el).find('.rate-list-grid-wrapper #srb-rate-list .unit-per-night').css({
              "flex-direction": "column",
              "align-items": "start"
            });
        }
      }

    });
    $('div.rate').each((i, el) => {
      if ($(el).find('.room')
        .not('.clone').length > 3) {
        $(el).find('.room')
          .not('.clone')
          .slice(3)
          .hide();
        $(el).find('.see-more')
          .show();
      } else {
        $(el).find('.see-more')
          .hide();
      }
    });
    $('.last-visible').removeClass('last-visible');
    $('#general-rate-list').each((i, el) => {
      $(el).find('li')
        .filter((j, el2) => {
          return $(el2).css('display') !== 'none';
        })
        .last()
        .addClass('last-visible');
    });
    $('#srb-rate-list').each((i, el) => {
      $(el).find('li')
        .filter((j, el2) => {
          return $(el2).css('display') !== 'none';
        })
        .last()
        .addClass('last-visible');
    });

    if (rooms.length === 0) {
      $('.none-found').show();
    } else {
      $('.none-found').hide();
    }
    if ((!isMobileWidth() && $('.rate-not-found').is(':visible')) && ($('.call-for-price').is(':visible') || $('.call-for-points').is(':visible'))) {
      $('.rooms-rates-listing .error-messaging .rate-not-found').addClass('error-msg-border');
    } else {
      $('.rooms-rates-listing .error-messaging .rate-not-found').removeClass('error-msg-border');
    }
  }
}

class RoomsRates {
  constructor() {
    this._params = {};
    this._data = {};
    this.totalRates = 0;
    this.rateCounter = 0;
    checkSessionForDualBrandImage();
    this.isGroupCodeExist = false;
     //rate-plans-to-skip-package-page
    this.ratePlanSkipPackage = ConfigsUtils.getConfigs('rate-plans-to-skip-package-page',_defaultChannelId,[_LOCALE_],ConfigsUtils.Any).reduce((result , item) => {
      return (item && item.name === 'RatePlanCode') ? JSON.parse(item.value) : result;
    },[]);
    this.addonsCategories = ConfigsUtils.getConfigs('add-ons-categories',_defaultChannelId,[_LOCALE_],ConfigsUtils.Any);


    this._emeaRegion = false;
    if ($_PAGE_.is('.rooms-rates-page')) {
      removeRoomsRates();
      handleTitleTag();

      EventHandler.one(EventHandler.BookingBarLoad, () => {
        this.initRoomsRates();
      });
    } else if ($_PAGE_.is('.modify-page')) {
      this.initRoomsRates();
    }
    RetailBannerUtils.slideDownRetailBanner();
  }

  initRoomsRates() {
    $('.mobile-dates .edit-dates').removeClass('hidden');
    this.initParams();
    this.fetchOverview();
    window.digitalData.roomsRates.filter.refinementType = '';
    window.digitalData.roomsRates.filter.refinementSelection = '';
    Analytics.updateSearchBrandValue();
    this.handleRoomsRatesMobileFilter();
    const propertyCardEl = document.querySelector('.property-card');
    //send flag true so that preference API should not be called twice
    RenderFavourite.fetchFavourite(propertyCardEl,overview_propertyId,"true");

    if (exists('.property-level-alert')) {
        if(isNotificationEnable($('.property-level-alert'))) {
          $('.property-level-alert').removeClass('hidden');
          $('.property-level-alert').click(function(e) {
            if (!$(e.target).is('[data-target]') && $(e.currentTarget).find('.accordion-link').length > 0) {
              $(e.currentTarget).find('.accordion-link')[0].click();
            }
          });
          $('.property-level-alert a:not(.accordion-link):not([data-target])').click((e) => {
            e.stopPropagation();
          });
        }
    }
    if(property_new && property_new === 'true') {
      let startDate = newRenovate_startDate ? decodeURIComponent(newRenovate_startDate) : '',
        endDate = newRenovate_enddate ? decodeURIComponent(newRenovate_enddate) : '';
      if(startDate && endDate && enableNewRenovatedPriceTag(startDate, endDate)) {
        $('.new-property-label').removeClass('hidden');
        $('.rooms-rates-page .newly-opened').removeClass('hidden');
        if (newRenovate_year) {
            $('.rooms-rates-page .newly-opened .new-renovate-year').html(' ' + newRenovate_year);
        }
      }
    }
    if(property_renovated && property_renovated === 'true') {
      let startDate = newRenovate_startDate ? decodeURIComponent(newRenovate_startDate) : '',
        endDate = newRenovate_enddate ? decodeURIComponent(newRenovate_enddate) : '';
      if(startDate && endDate && enableNewRenovatedPriceTag(startDate, endDate)) {
        $('.new-property-label').removeClass('hidden');
        $('.rooms-rates-page .renovated').removeClass('hidden');
        if (newRenovate_year) {
          $('.rooms-rates-page .renovated .new-renovate-year').html(' ' + newRenovate_year);
        }
      }
    }
    if(isPriceTag && isPriceTag === 'true') {
      let startDate = priceTag_startDate ? decodeURIComponent(priceTag_startDate) : '',
        endDate = priceTag_enddate ? decodeURIComponent(priceTag_enddate) : '';
      if(startDate && endDate && enableNewRenovatedPriceTag(startDate, endDate)) {
        $('.rooms-rates-page .price-badge').removeClass('hidden');
        $('.rooms-rates-page .mini-booking-price-badge').hide();
      }
    }

  }

  initParams() {
    if(overview_propertyId){
      setCriteria({
        propertyId: overview_propertyId
      });
    }
    this._params = $.extend(true, {}, getCriteria());
    if (isNaN(parseInt(this._params.propertyId.charAt(0)))) {
      // If first character is a letter, format is WY##### instead of #####.
      // Strip first 2 characters to avoid breaking services
      this._params.propertyId = this._params.propertyId.slice(2);
    }
    this._params.orsId = overview_orsId || this._params.orsId;
    this._params.altPropId = overview_orsId || this._params.altPropId;
    this._params.brandId = overview_brandId || this._params.brandId;

    //check-in and check-out logic
    this._params.checkInDate = getDateFromDateString(BBForm.getFrom());
    this._params.checkInDate = formatDateForBWSAvailability(this._params.checkInDate);
    this._params.checkOutDate = getDateFromDateString(BBForm.getTo());
    this._params.checkOutDate = formatDateForBWSAvailability(this._params.checkOutDate);

    if(!$_PAGE_.is('.modify-page') && WRNonParticipatingBrandsConfig.isCurrentBrandParticipatingToWR()) {
      if (this._params.useWRPoints && _isWeakTrue(this._params.useWRPoints)) {
        BBForm.setRedeemWRPoint(true);
        setCriteria({
          useWRPoints: true
        });
        $('#wyndham-rewards-filter').prop('checked', true);
      } else {
        BBForm.setRedeemWRPoint(false);
        setCriteria({
          useWRPoints: false
        });
        $('#price-filter').prop('checked', true);
      }
    } else {
      BBForm.setRedeemWRPoint(false);
      setCriteria({
        useWRPoints: false
      });
      this._params.useWRPoints = false;
    }

    let warnParamValue = getQueryParameter('groupCode');
    if (warnParamValue && (warnParamValue.length > 0)) {
      this.isGroupCodeExist = true;
    }
  }

  handleRoomsRatesMobileFilter() {
    if ($_PAGE_.is('.rooms-rates-page')) {
      // Filter by points and money
      let checkFilter = () => {
        if ($('#price-filter').is(':checked')) {
          $('.mini-booking-container #rewardspricefiltermobile label').removeClass('selected');
          $('.mini-booking-container #rewardspricefiltermobile label:eq(0)').addClass('selected');
        } else if ($('#wyndham-rewards-filter').is(':checked')) {
          $('.mini-booking-container #rewardspricefiltermobile label').removeClass('selected');
          $('.mini-booking-container #rewardspricefiltermobile label:eq(1)').addClass('selected');
        }
      };
      if (isMobileWidth() && WRNonParticipatingBrandsConfig.isCurrentBrandParticipatingToWR() && !['av','lt'].includes(window.brand_id)){
      $('.mini-booking-container').append('<div class="visible-xs row filter-mobile-price">' + $('.refine-result-container .refine-result-content .panel').clone(true, true)
        .html()
        .replace(/rewardspricefilter/g, 'rewardspricefiltermobile') + '</div>');
      }
      $('.mini-booking-container').find('#rewardspricefiltermobile label input')
        .remove();
      $('.mini-booking-container .indicator').remove();
      $('.mini-booking-container #rewardspricefiltermobile label').click((e) => {
        $('.mini-booking-container #rewardspricefiltermobile label').removeClass('selected');
        $(e.currentTarget).addClass('selected');
      });
      $('.refine-result-wrapper #rewardspricefilter label').click(() => checkFilter());
      setTimeout(() => {
        let p = this._params;
        if (p.useWRPoints && _isWeakTrue(p.useWRPoints)) {
          $('.mini-booking-container #rewardspricefiltermobile label:eq(1)').addClass('selected');
        } else {
          $('.mini-booking-container #rewardspricefiltermobile label:eq(0)').addClass('selected');
        }
        checkFilter();
      }, 1000);
      // Dates relocation.
      $('.mini-booking .mobile-dates').html($('.mini-booking .mobile-dates').html()
        .replace('|', ''));
      $('.mini-booking .mobile-dates').prependTo('.mini-booking-container');
      $('.mini-booking-container .mobile-dates .edit-dates').addClass('btn-link');
      $('.mini-booking-container .mobile-dates .edit-dates').on('click', (e) => {
        e.preventDefault();
        $('#magnifyingGlassButton').triggerHandler('click');
        if(isMobileWidth()){
          $('.top-nav-item.book').addClass('open');
        }
        $('[name="destination"]').addClass('hidden');
        $('.booking-input-container.destination-container').find('.destination-text').remove();
        $('.booking-input-container.destination-container').append('<div class="destination-text headline-f" >' + this._data.overview.hotelName + '</div>');
        $('.booking-bar-main').find('.search-btn').text($('.mini-booking-nav').find('.search-btn').attr('original-label'));
        // $('.mini-booking .check-in-button').triggerHandler('click');
        // $('.mini-booking .mini-booking-nav').css('zIndex', 999);
        // setTimeout(() => {
        //   let activeDateParent = $('.active-date-check-in').closest('.ui-datepicker-group'),
        //     newTop = $('.ui-datepicker-inline').scrollTop();
        //   if(activeDateParent.length) {
        //     newTop += activeDateParent.position().top;
        //   }
        //   $('.ui-datepicker-inline').scrollTop(newTop);
        // }, 2000);
      });
    }
  }

  handleRoomsRatesErrorMessaging(res) {
    if(res){
      EventHandler.triggerEvent('brands-error', res);
    }
    $(
      '.rooms-rates-page .section-title-container, .rooms-rates-page .booking-status-container, .rooms-rates-page .rooms-rates-listing-container, .modify-page .rooms-rates-listing-container'
    ).hide();

    $('.rooms-rates-page .no-rates-available-header-container, .modify-page .no-rates-available-header-container').show();

    if (BBForm.minLosIsNotMet()) {
      BBForm.errorDatePickerButtons();

      $('.rooms-rates-page .no-rates-available-header-container .min-los-not-met, .modify-page .no-rates-available-header-container .min-los-not-met').show();
    } else {
      $('.rooms-rates-page .no-rates-available-header-container .rooms-unavailable, .modify-page .no-rates-available-header-container .rooms-unavailable').show();
      updateCurrencySelectorCode('Currency',currencyRate);
    }
  }

  async fetchOverview() {
    try {
      const overview = await OverviewService.getOverview(this._params.propertyId);
      this.handleOverviewSuccess(overview);
    } catch (error) {
      console.error('Error fetching overview:', error);
    }
  }
  handleOverviewSuccess(obj){
    this._data.overview = obj;
    setSearchOverview(obj, true);
    if (countryCodeList && isAllInPriceRegion()) {
      this._emeaRegion = true;
    }
    this.populateOverviewView(); //populate overview dependant data like TA
    EventHandler.send(EventHandler.property.search.success);
    //invoke individual segments
    this.fetchRoomsRates(obj);
  }
  populateOverviewView() {
    let pdata = this._data.overview;

    $('#rateSummaryDetail .check-in .time').html(formatTimeShow(pdata.hotelPolicies.checkInTime));
    $('#rateSummaryDetail .check-out .time').html(formatTimeShow(pdata.hotelPolicies.checkOutTime));

    EventHandler.on(EventHandler.UNAP.updated, () => {
      let shouldHideProvinceCountry = HideProvinceCountryConfig.getHideProvinceCountryConfig(getSessionCriteria().propertyId);

      let address = shouldHideProvinceCountry ? getAddressWithoutProvinceCountry() : getAddress();
      $('.property-name .property-address .prop-addr').text(address);
    });
    this.wrapRoomPropertyNameText();
    let phoneNumber = pdata.phone1;
    if(property_country_code && (property_country_code === 'CN' || property_country_code === 'TW' || property_country_code === 'HK' || property_country_code === 'MO')) {
      if(phoneNumber.indexOf('+') !== 0) {
        phoneNumber = '+' + phoneNumber;
      }
    }
    setUNAP({
      name: pdata.hotelName,
      address: pdata.address1 + (pdata.address2 ? ' ' + pdata.address2 : '') + (pdata.address3 ? ' ' + pdata.address3 : ''),
      city: pdata.city,
      state: pdata.state,
      country: pdata.country,
      postalCode: pdata.postalCode,
      phone: phoneNumber
    });
  }

  wrapRoomPropertyNameText() {
    if((isTabletWidth()) && $(".property-name.mobile-edit-dates .prop-name").eq(0).length) {
      $(".property-name.mobile-edit-dates .prop-name").eq(0).css({"display": "inline-block","width": $(".property-name.mobile-edit-dates .prop-name").eq(0).parent().parent().width() - $(".property-name.mobile-edit-dates #currencyDropDownReferenceRR").eq(0).width()});
      window.addEventListener("resize", function() {
        $(".property-name.mobile-edit-dates .prop-name").eq(0).css({"display": "inline-block","width": $(".property-name.mobile-edit-dates .prop-name").eq(0).parent().parent().width() - $(".property-name.mobile-edit-dates #currencyDropDownReferenceRR").eq(0).width()});
      });
    }
  }

  fetchRoomsRates(res) {
    if (BBForm.minLosIsNotMet()) {
      this.handleRoomsRatesErrorMessaging(res);
      return;
    }

    let p = {};
    p.brand_id = this._params.brandId;
    p.checkout_date = this._params.checkOutDate;
    p.checkin_date = this._params.checkInDate;
    p.adults = this._params.adults;
    p.children = this._params.children;
    p.childAge = this._params.childAge;
    p.rooms = this._params.rooms;
    p.ratePlan = this._params.ratePlan;
    p.rate_code = this._params.rateCode;
    p.group_code = this._params.groupCode;
    p.corporate_id = this._params.corpCode;

    //Add additionalCorpCodes param for mobile view only
    if (isMobileWidth() && _isNotNull(additionalCorpCodes)) {
      p.additionalCorpCodes = additionalCorpCodes;
    }

    if(isWRCCHolder() && _isNotNull(wrccCorpCodes)) {
      if(p.additionalCorpCodes) {
        p.additionalCorpCodes = p.additionalCorpCodes + '|' + wrccCorpCodes;
      } else {
        p.additionalCorpCodes = wrccCorpCodes;
      }
    }

    p.propertyId = this._params.propertyId;
    p.sorting = this._params.sorting;
    p.isOrsId = this._params.orsId;
    p.altPropId = this._params.altPropId;
    p.region = this._params.region;
    p.useWRPoints = this._params.useWRPoints;
    p.language = _LOCALE_;

    //FNS OR POINTS use cases - START
    if (isLoyaltyRP(p.rateCode) || _isWeakTrue(p.useWRPoints)) {
      p.useWRPoints = true;
      this._isFNS = true;
    }

    //FNS OR POINTS use cases - END
    if (p.brandId === 'WY') {
      p.isOrsId = true;
      p.altPropId = p.orsId;
    }
    p.useWRPoints = getCriteria().useWRPoints;
    let wantedWRPoints = p.useWRPoints;
    if (_isWeakTrue(p.useWRPoints)) {
      p.corpCode = p.groupCode = p.rateCode = p.ratePlan = '';
    } else {
      if (_isNotNull(p.corpCode) || _isNotNull(p.groupCode) || _isNotNull(p.rateCode)) {
        $('[for=wyndham-rewards-filter]').off('click')
          .addClass('disabled');

        p.useWRPoints = false;
      } else {
        p.useWRPoints = true;
      }
    }

    callService('roomsRates', p).then((res) => {
      if (res && res.status === 'OK') {
        setRoomsRates(res.roomsAndRates, true);
        let sortedRoomsAndRates = res.roomsAndRates;
        //Separate rooms into standard and accessible
        const standardRooms = sortedRoomsAndRates.rooms.filter(room => !room.accessible);
        const accessibleRooms = sortedRoomsAndRates.rooms.filter(room => room.accessible);
        //Merge rooms in order standard rooms and accessible rooms
        sortedRoomsAndRates.rooms = [...standardRooms, ...accessibleRooms];
        this._data.roomsAndRates = sortedRoomsAndRates;

        this.fetchRoomImages();
        this.fetchRoomAmenities($('.listing .rooms-slide .room').not('.clone')
          .map((i, el) => {
            return $(el).attr('room');
          })
          .get()
          .join(','));

        this.populateView();

        if (_isWeakTrue(wantedWRPoints) || $('#wyndham-rewards-filter').is(':checked')) {
          this.Filter.filterListing(true);

          $('#wyndham-rewards-filter').prop('checked', true);

          setCriteria({
            useWRPoints: true
          });
          if (($('div.points-rate, li.points-rate').css('display') !== undefined) || ($(
            'div.points-rate, li.points-rate').css('display') !== 'none')) {
            $('.rate-not-found').hide();
          }
        } else {
          this.Filter.filterListing(false);
        }

        if (!isMobileWidth()) {
          $('.rooms-slide .room').not('.clone')
            .first()
            .find('.show-hide-rates')
            .click()
            .removeAttr('style');
        }
        Analytics.updateRoomsData();
        Analytics.updateRatesData();
      } else {
            this.handleRoomsRatesErrorMessaging(res);
            if (isDesktopWidth() && brand_id !== 'wr' && $_PAGE_.is('.rooms-rates-page')) {
                $('#bookingBarCollapsed').css('visibility','visible');
            }
      }
    });



  }

  fetchRoomImages() {
    let pr = {};

    pr.locale = _LOCALE_;
    pr.brandId = getBrand(true);
    pr.hotelId = overview_propertyId || getCriteria().propertyId;
    pr.country = property_country_code;
    pr.stateProv = property_state_code;
    pr.roomTypes = '';
    pr.brandTier = getBrandTier().toUpperCase();
    let roomTypesArr = [];

    $.each(this._data.roomsAndRates.rooms, (i, rm) => {
      if (!($.inArray(rm.roomTypeCode, roomTypesArr) >= 0)) {
        roomTypesArr.push(rm.roomTypeCode);
      }
    });

    $.each(roomTypesArr, (ind, roomType) => {
      pr.roomTypes += roomType + ',';
    });

    callService(getServiceUrl('getRoomImage'), pr).then((imres) => {
      if (imres) {
        this._roomImageData = imres;
        for (let roomType in imres) {
          if (imres.hasOwnProperty(roomType)) {
            let imgArray = imres[roomType] || [];
            const imgWrapper = $('.room[room="' + roomType + '"]').find('.img-placeholder-container');
              imgWrapper.empty();
              imgArray.forEach(imageObj => {
                let image = document.createElement('img');
                image.dataset.mobileSrc = DOMPurify.sanitize(imageObj.mobile);
                image.dataset.tabletSrc = DOMPurify.sanitize(imageObj.tablet);
                image.dataset.src = DOMPurify.sanitize(imageObj.desktop);
                image.setAttribute('alt', DOMPurify.sanitize(imageObj.altText));
                image.setAttribute('src', DOMPurify.sanitize(imageObj.mobile));
                image.classList.add('lazy-load');
                imgWrapper.append(image);
              });

              if(imgWrapper.children().length > 0) {
                imgWrapper.slick( {dots: true});
              }
              // dsiable propogation on arrow click
              imgWrapper.on('click', (event) => {
              const target = event.target;
              const isSlickButton = target.tagName;
                if(isSlickButton && isSlickButton.toLowerCase() === 'button') {
                  event.stopPropagation();
                }
              });
          }
        }

        lazyLoadImageInViewport('.room img.lazy-load');
        // lazyLoadImage($('.room img'));
        ResizeHandler.addResizeEndFn(() => {
          lazyLoadImageInViewport('.room img.lazy-load');
          // lazyLoadImage($('.room img'));
        });
      }
    });
  }

  fetchRoomAmenities(rts) {
    OverviewService.getOverview(this._params.propertyId, rts)
      .then((data) => {
        Analytics.updatePropTierNum(data.TierNum);
        this.populateRoomAmenities(data.roomAmenities);
        this.checkCaesarsAmenity(data.otherAmenities);
        if (isDesktopWidth() && brand_id !== 'wr' && $_PAGE_.is('.rooms-rates-page')) {
          $('#bookingBarCollapsed').css('visibility', 'visible');
        }
      })
      .catch((error) => {
        console.error('Error fetching room amenities:', error);
      });
  }

  checkCaesarsAmenity(otherAmenities) {
    let deleteCookie = true;
    $.each(otherAmenities, (c, amenity) => {
      $.each(amenity.groupDetails, (count, detail) => {
        if (detail.amenityNameEN !== '' && detail.amenityNameEN === 'Caesars Enrollment') {
          deleteCookie = false;
          CookieHandler.createCookie('caesarsAmenity', 'true');
        }
      });
    });

    if (deleteCookie) {
      CookieHandler.eraseCookie('caesarsAmenity');
    }
  }

  populateRoomAmenities(radata) {
    var amenities_array = [],
      accessible_amenities = [];
    if (radata && radata.length > 0) {
      $.each(radata, (c, ra) => {
        let $rm = $('.room[room="' + ra.roomType + '"]');
        if (ra.amenitiesDetails && ra.amenitiesDetails.length > 0) {
          $.each(ra.amenitiesDetails, (ci, rc) => {
            if (rc.groupDetails && rc.groupDetails.length > 0) {
              $.each(rc.groupDetails, (cii, rat) => {
                let dtxt = '';
                if (rat.amenityCharge === 'Y') {
                  dtxt = '*';
                }
                amenities_array.push(rat.amenityName + dtxt);
              });
            }
          });
          amenities_array.sort((a, b) => {
            let x = a.toLowerCase(),
              y = b.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
          $rm.data('amenity-list', amenities_array);
          amenities_array = [];
        }
        if (ra.featuresDetails && ra.featuresDetails.length > 0) {
          $.each(ra.featuresDetails, (ci, rc) => {
            accessible_amenities.push(rc.featureName);
          });
          accessible_amenities.sort((a, b) => {
            let x = a.toLowerCase(),
              y = b.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
          $rm.data('accessible-amenities-list', accessible_amenities);
          accessible_amenities = [];
        }
      });
    }
  }

  populateView() {
    this.Filter = new RoomsRatesFilter(this);

    this.populateRoomView();
    this.Filter.populateFilters();
  }

  populateRoomView() {
    let roomsrates = this._data.roomsAndRates;

    if (roomsrates && roomsrates.rooms && roomsrates.rooms.length > 0) {
      //Sort the room
      roomsrates.rooms = sortRoomRatePlans(this._data.roomsAndRates.rooms, getCriteria(), UserHandler.isWRLoggedIn(), isMobileWidth());
      let rooms = roomsrates.rooms,
        bedData = this.Filter._filterData.bedType,
        roomData = this.Filter._filterData.roomType,
        accessibleData = this.Filter._filterData.accessibleType;

      // Find total number of rates
      for(let i = 0; i < rooms.length; i++){
        this.totalRates += rooms[i].rates.length;
      }

      $.each(rooms, (counter, room) => {
        let $rm = $('.listing .rooms-slide .room.clone').clone()
          .removeClass('clone');
        // this is adding class to anything over 3 for room view
        if (counter > 2) {
          $rm.addClass('hidden-after-three');
        }
        if (overview_brandId === 'WY') {
          if (room.shortName) {
            $('.room-title .name-toggle', $rm).text((room.shortName));
          } else {
            if (room.description) {
              $('.room-title .name-toggle', $rm).text((((room.description).replace(/-/, '').split(/\s+/)
                .slice(
                  0, 3)
                .join(' ')) + '..'));
            }
          }
        } else {
          $('.room-title .name-toggle', $rm).text((room.shortName));
        }
        $('.rr-amenity-list', $rm).text(room.description);

        if (room.accessible) {
          $('.room-detail-link.hidden-xs', $rm).text($('.room-detail-link.hidden-xs', $rm).text() + " " + ($('.room-detail-link.hidden-xs', $rm).data('accessibleamenities') || ''));
          $('#general-rate-list .room-detail-link', $rm).text($('.room-detail-link.hidden-xs', $rm).text());
          $('#srb-rate-list .room-detail-link', $rm).text($('.room-detail-link.hidden-xs', $rm).text());
        }

        this.bindLightboxLink($rm, room);

        $rm.attr('visible-index', counter);
        $rm.attr('room', room.roomTypeCode);
        $rm.attr('nsmk', room.nonSmoking);
        $rm.attr('roomcat', room.bedType);
        $rm.attr('accessible',room.accessible);
        let lowRate = Number.MAX_VALUE,
          pacLowRate = Number.MAX_VALUE,
          pacPoints = Number.MAX_VALUE,
          chkDisableRoomInventory = (disableRoomInventory !== '' && _isNotNull(disableRoomInventory) && _isWeakTrue(disableRoomInventory)) ? true : false;

        if ((chkDisableRoomInventory && room.inventoryCount > 0) || !chkDisableRoomInventory) {
          $.each(room.rates, (counter, ratesec) => {
            this.popRates(ratesec, $rm, room); // room is entire room object
            if (this._emeaRegion && ($('.pricing .taxes-fees span', $rm).text().trim().length > 0)) {
              //DAI-2002 : SVCRUN-48 : As services as sending ratesec.nightAfterTaxMin as null, ratesec.nightRatesAfterTax[0] is used as fallback
              //ratesec.averageAfterTax will used if ratesec.nightRatesAfterTax[0] is also null
              if (ratesec.pacRatePlan && ratesec.nightRatesAfterTax[0] < pacLowRate) {
                pacLowRate = (ratesec.nightRatesAfterTax[0] > 0) ? ratesec.nightRatesAfterTax[0] : ratesec.averageAfterTax;
                pacPoints = ratesec.pacPoints;
              } else if (!(ratesec.fnsRatePlan) && ratesec.nightRatesAfterTax[0] < lowRate) {
                if (!ratesec.cugRate || UserHandler.isWRLoggedIn()) {
                  lowRate = (ratesec.nightRatesAfterTax[0] > 0) ? ratesec.nightRatesAfterTax[0] : ratesec.averageAfterTax;
                }
              }
            } else {
              if (ratesec.pacRatePlan && ratesec.nightBeforeTaxMin < pacLowRate) {
                pacLowRate = ratesec.nightBeforeTaxMin;
                pacPoints = ratesec.pacPoints;
              } else if (!(ratesec.fnsRatePlan) && ratesec.nightBeforeTaxMin > 0 && ratesec.nightBeforeTaxMin <
                lowRate) {
                if (!ratesec.cugRate || UserHandler.isWRLoggedIn()) {
                  lowRate = ratesec.nightBeforeTaxMin;
                }
              }
            }

            if (ratesec.fnsRatePlan) {
              room.isSRBMatch = true;
              room.fnsPoints = ratesec.fnsPoints;
            }
          });
          if ($('#general-rate-list > .rate', $rm).not('.clone').length > 3) {
            $('#general-rate-list > .rate', $rm).not('.clone')
              .slice(3)
              .hide();
            let seeMore = $('#general-rate-list .see-more', $rm);
            seeMore.click((e) => {
              e.preventDefault();
              if (seeMore.hasClass('see-less')) {
                $('#general-rate-list > .rate:visible', $rm).not('.clone')
                  .slice(3)
                  .hide();
                let more = seeMore.find('.see-more-text').data('more'),
                  seeMoreRoomsHeight = getElTrueHeight(seeMore);
                seeMore.find('.see-more-text').text(more);
                seeMore.removeClass('see-less');
                // On "See Less" button click- focus to "See More" button at bottom of viewport
                scrollToAnimated(($(seeMore).offset().top - (getWindowHeight() - seeMoreRoomsHeight)) + 10);
              } else {
                if (this._isFNS) {
                  $('#general-rate-list > .rate.points-rate', $rm).show();
                } else {
                  $('#general-rate-list > .rate.cash-rate', $rm).not('.clone')
                    .show();
                }
                let less = seeMore.find('.see-more-text').data('less');
                seeMore.find('.see-more-text').text(less);
                seeMore.addClass('see-less');
              }
              Analytics.updateRatesData();
            });
          } else {
            $('#general-rate-list .see-more', $rm).remove();
          }
          if ($('#srb-rate-list > .rate', $rm).not('.clone').length > 3) {
            $('#srb-rate-list > .rate', $rm).not('.clone')
              .slice(3)
              .hide();
            let seeMore = $('#srb-rate-list .see-more', $rm);
            seeMore.click((e) => {
              e.preventDefault();
              if (seeMore.hasClass('see-less')) {
                $('#srb-rate-list > .rate:visible', $rm).not('.clone')
                  .slice(3)
                  .hide();
                let more = seeMore.find('.see-more-text').data('more'),
                  seeMoreRoomsHeight = getElTrueHeight(seeMore);
                seeMore.find('.see-more-text').text(more);
                seeMore.removeClass('see-less');
                // On "See Less" button click- focus to "See More" button at bottom of viewport
                scrollToAnimated(($(seeMore).offset().top - (getWindowHeight() - seeMoreRoomsHeight)) + 10);
              } else {
                if (this._isFNS) {
                  $('#srb-rate-list > .rate.points-rate', $rm).show();
                } else {
                  $('#srb-rate-list > .rate.cash-rate', $rm).not('.clone')
                    .show();
                }
                let less = seeMore.find('.see-more-text').data('less');
                seeMore.find('.see-more-text').text(less);
                seeMore.addClass('see-less');
              }
              Analytics.updateRatesData();
            });
          } else {
            $('#srb-rate-list .see-more', $rm).remove();
          }
          $('.unavail', $rm).hide();
          //populate rate plan level data
          if (!this.isGroupCodeExist) {
            if (room.inventoryCount <= 5) {
              if (room.inventoryCount === 0) {
                $rm.attr('avail', false);
                $('.unavail', $rm).show();
                if(!chkDisableRoomInventory) {
                  $('.multiple-room', $rm).remove();
                  $('.single-room', $rm).remove();
                }
              } else {
                $('.warn .num-rooms', $rm).text(room.inventoryCount);
                (room.inventoryCount == 1) ? ($('.multiple-room', $rm).remove()) : ($('.single-room', $rm).remove());
              }
            } else {
              $('.warn', $rm).remove();
            }
          } else {
            $('.warn', $rm).remove();
          }
          $('.from-rate .pricing .unit', $rm).first()
            .text(this.currencyCode);
          getCurrencyMapping(this.currencyCode, false);
          if (lowRate < Number.MAX_VALUE) {
            let moneySplit = toMoney(convertToCurrency(lowRate,this.currencyCode)).split('.');
            $('.from-rate .pricing .cash-rate', $rm).html((getSelectedCurencyCode(this.currencyCode) == 'USD' ? '$' : '') +
              moneySplit[0] + '<div class="superscript">' + moneySplit[1] + '</div> <span class="strike-element"></span>');
          }
          if (pacLowRate < Number.MAX_VALUE && pacLowRate > 0) {
            let moneySplit = toMoney(convertToCurrency(pacLowRate,this.currencyCode)).split('.');
            $('.from-rate .pricing .pac-rate', $rm).html((getSelectedCurencyCode(this.currencyCode) == 'USD' ? '$' : '') +
              moneySplit[0] + '<div class="superscript">' + moneySplit[1] + '</div> <span class="strike-element"></span>');
            $('.from-rate .pricing .plus-points', $rm).text('+ ' + formatNumber(pacPoints));
            if(!this.minimumPoint || this.minimumPoint > parseInt(pacPoints)){
              this.minimumPoint = parseInt(pacPoints);
            }
          } else {
            $('.from-rate .pricing .pac-rate, .from-rate .pricing .plus-points, .from-rate .or', $rm).remove();
            // show "Show rates from" text in points div
          }
          //Point and dollar use cases - START
          //WRCCBarclay changes
          if (room.isSRBMatch) {
            $('.from-rate .points .point-val', $rm).text(formatNumber(parseInt(room.fnsPoints)));
            if(!this.minimumPoint || this.minimumPoint > parseInt(room.fnsPoints)){
              this.minimumPoint = parseInt(room.fnsPoints);
            }
            $('.from-rate .or, .from-rate .points', $rm).show();
          } else {
            $('.from-rate .or, .from-rate .points', $rm).remove();
          }
          //Point and dollar use cases - END
          $rm.attr('avail', true);
        } else {
          $('.rooms-rates-prices .pricing', $rm).remove();
          $('.rooms-rates-prices .show-hide-rates', $rm).remove();
          $('.rooms-rates-prices .unavail', $rm).show();

          $rm.attr('avail', false);
        }

        if ($rm.attr('avail') !== false && $rm.attr('avail') !== 'false') {
          bedData[room.bedType] = (bedData[room.bedType] || 0) + 1;
          let smokingtype = (room.nonSmoking ? 'nsmk' : 'smk');
          roomData[smokingtype] = (roomData[smokingtype] || 0) + 1;
          let accessibletype = (room.accessible ? 'accessible' : 'nonaccessible');
          accessibleData[accessibletype] = (accessibleData[accessibletype] || 0) + 1;
          if(room.rates[0] != undefined && isWRCCRate(room.rates[0].ratePlanId)){
            if($rm.find('.cug-wrcc-rate').length && $rm.find('.cug-member-rate').length) {
              $rm.find('.cug-member-rate').remove();
            }
          } else {
            if($rm.find('.cug-wrcc-rate').length && $rm.find('.cug-member-rate').length) {
              $rm.find('.cug-wrcc-rate').insertBefore($rm.find('.cug-member-rate')[0]);
              $rm.find('.cug-member-rate').remove();
            }
          }
          $('.listing .rooms-slide').append($rm);
        }
      });

      console.log(this.minimumPoint , ConfigsUtils.getViewCashOrPointsConfig());
      let ViewCashOrPointsConfig = ConfigsUtils.getViewCashOrPointsConfig();
      if(parseInt(this._params.rooms) === 1 && this.minimumPoint && ViewCashOrPointsConfig.attributes && ViewCashOrPointsConfig.attributes.hasOwnProperty('viewPointsOnRoomsRates')){
        let cookieSession = CookieHandler.getSession();
        if(ViewCashOrPointsConfig.attributes['viewPointsOnRoomsRates'] && cookieSession && cookieSession.points >= this.minimumPoint){
          if(ViewCashOrPointsConfig.attributes.hasOwnProperty('minimumViewPoints') && parseInt(ViewCashOrPointsConfig.attributes.minimumViewPoints) <= cookieSession.points){
            let RegisteredPromotion =  {};
            if(ViewCashOrPointsConfig.attributes.hasOwnProperty('PromotionOverride') && ViewCashOrPointsConfig.attributes.PromotionOverride){
              callService('getPromotions',{}).then((res) => {
                console.log(res.data.filter(item=>item.registered));
                RegisteredPromotion =  res.data.filter(item=>item.registered);

                if(!RegisteredPromotion.length > 0 && !((ViewCashOrPointsConfig.attributes.hasOwnProperty('CorpCodeOverride') && ViewCashOrPointsConfig.attributes.CorpCodeOverride && this._params.corpCode) ||
                (ViewCashOrPointsConfig.attributes.hasOwnProperty('GroupCodeOverride') && ViewCashOrPointsConfig.attributes.GroupCodeOverride && this._params.groupCode))){
                  window.digitalData.search.searchInfo.rewardsPoints = 'Yes';
                  $('#wyndham-rewards-filter').prop('checked', true);
                  $('#wyndham-rewards-filter').click();
                  if(isMobileWidth()){
                    $('.call-for-mobile-point').show();
                  }else if(isDesktopWidth() || isTabletWidth()){
                    $('.call-for-desktop-point').show();
                  }
                }
              });
            }else if(!((ViewCashOrPointsConfig.attributes.hasOwnProperty('CorpCodeOverride') && ViewCashOrPointsConfig.attributes.CorpCodeOverride && this._params.corpCode) ||
              (ViewCashOrPointsConfig.attributes.hasOwnProperty('GroupCodeOverride') && ViewCashOrPointsConfig.attributes.GroupCodeOverride && this._params.groupCode))
            ){
              window.digitalData.search.searchInfo.rewardsPoints = 'Yes';
              $('#wyndham-rewards-filter').prop('checked', true);
              $('#wyndham-rewards-filter').click();
              if(isMobileWidth()){
                $('.call-for-mobile-point').show();
              }else if(isDesktopWidth() || isTabletWidth()){
                $('.call-for-desktop-point').show();
              }
            }

          }
        }
      }




      // BNPL INIT
      if(!isBNPLEX(getSearchOverview())){
        BNPLUplift.handleConfig({
          currencyCode: this.currencyCode,
          checkinDate: formatDateForUplift(getCriteria().checkInDate),
          checkoutDate: formatDateForUplift(getCriteria().checkInDate),
          checkout: false
        });
      } else {
        BNPLUplift.trackBNPLBrandStatus(this.currencyCode);
      }

      // rooms with hidden class and calls seeMoreLess func
      let currHiddenRooms = $('.rooms-slide div.room.hidden-after-three');
      let roomsBtn = $('.room-slide-level-see-more-btn');
      // Strike Through Rates Styling
      this.strikeThroughRates();
      this.seeMoreLess(currHiddenRooms, roomsBtn);
      $('.price-badge').click(function() {
        if(!isMobileWidth()) {
          $(this).closest('li.rate').find('.book-btn a')[0].click();
        }
      });

      // for TPD analytics
      // fire secondary (async) alanytics event for rooms-rates page
      if (roomsrates.rooms[0].rates) {
        const rate = roomsrates.rooms[0].rates[0];
        const priceDisplayType = rate ? rate.priceDisplayType : '';

        const tpdExperience = sessionStorage.getItem('tpdExperience');
        // get updated TPD value. to be implemented in future (DAI-38757)
        const updatedTPDValue = getUpdatedTPDTypeValue(priceDisplayType);
        // if session variable does exists or is not same as priceDisplayType fire an async call
        if (!tpdExperience || tpdExperience.toUpperCase() !== updatedTPDValue.toUpperCase()) {
          // set session storage variable
          sessionStorage.setItem('tpdExperience', updatedTPDValue);
          if (window.digitalData) {
            window.digitalData.totalPriceDisplay = window.digitalData.totalPriceDisplay || {};
            window.digitalData.totalPriceDisplay.experience = updatedTPDValue;
          }
          if (window._satellite && updatedTPDValue) {
            setTimeout(() => {
              console.log('in rooms rates page fire analytics now: ', updatedTPDValue);
              window._satellite.track('totalPriceDisplayExperience');
            }, 2000);
          }
        }
      }
    } else {
      let searchLink = search_redirect_url + location.search;
      $('.roomsrates-norooms-message').find('a')
        .attr('href', searchLink);
      $('.roomsrates-norooms-message').show();
      $('.booking-status').hide();
      $('#available-rooms---rates').hide();
      $('.rooms-rates-listing.row').hide();
    }
  }

  bindLightboxLink($rm, room) {
    $('.room-detail-link', $rm).not('.room-img-wrapper').click(() => {
      let msg = '<div class="room-detail-modal-content modal-small">';
      msg += '<h4 class="modal-title headline-e">' + room.shortName + '</h4>';
      // cloning the first img wrapper
      msg += $('<div>').append($('.room-details .img-placeholder-container img.slick-active', $rm).clone())
        .html();

      if ($rm.data('accessible-amenities-list') && $rm.data('accessible-amenities-list').length > 0) {
        if ($('.room-detail-link', $rm).data('show-icon')) {
          msg += `<h5 class="accessibility-heading headline-e"> <img class="accessible-icon" src="${$('.room-detail-link', $rm).data('accessible-icon-path') || ''}" alt="${$('.room-detail-link', $rm).data('accessible-icon-alt') || ''}">${$('.room-detail-link', $rm).data('feature-label')}</h5>`;
        } else {
          msg += `<h5 class="amenity-heading headline-e">${$('.room-detail-link', $rm).data('feature-label')}</h5>`;
        }
        msg += '<ul class="rooms-rates-amenity-list" itemscope itemtype="http://schema.org/HotelRoom">';
        let amenities = $rm.data('accessible-amenities-list');
        for (let amenity = 0; amenity < amenities.length; amenity++) {
          msg += '<li itemprop="amenityFeature" itemscope itemtype="http://schema.org/LocationFeatureSpecification"><span itemprop="name">' + amenities[amenity] + '</span><meta itemprop="value" content="True"></li>';
        }
        msg += '</ul>';
      }
      if ($rm.data('amenity-list') && $rm.data('amenity-list').length > 0) {
        msg += '<h5 class="amenity-heading headline-e">' + $('.room-detail-link', $rm).data('amenity-label') + '</h5>';
        msg += '<ul class="rooms-rates-amenity-list" itemscope itemtype="http://schema.org/HotelRoom">';
        let amenities = $rm.data('amenity-list');
        for (let amenity = 0; amenity < amenities.length; amenity++) {
          msg += '<li itemprop="amenityFeature" itemscope itemtype="http://schema.org/LocationFeatureSpecification"><span itemprop="name">' + amenities[amenity] + '</span><meta itemprop="value" content="True"></li>';
        }
        msg += '</ul>';

      }
      if ((!$rm.data('accessible-amenities-list') || ($rm.data('accessible-amenities-list') && $rm.data('accessible-amenities-list').length == 0)) && (!$rm.data('amenity-list') || ($rm.data('amenity-list') && $rm.data('amenity-list').length == 0))) {
        msg += $('.room-detail-link', $rm).data('amenities-not-found');
      }

      msg += '</div>';
      this.showLightbox(msg);
      return false;
    });
  }

  showLightbox(msg) {
    $_GENERIC_LIGHTBOX_.modal({
      show: true,
      remote: false
    }).find('.modal-content')
      .html(msg);
  }

  popRates(ratesecs, $rm, room) { // ratesecs is each object in room.rates array, $rm is rooms element, room is entire room object
    this.rateCounter += 1;

    let rateSeg = this.getRate(ratesecs.ratePlanId),
      $rrc = $('#general-rate-list', $rm).find('.rate.clone')
        .clone()
        .removeClass('clone'),

      $rrsrb = $('#srb-rate-list', $rm).find('.rate.clone')
        .clone()
        .removeClass('clone'),
      totPoints = parseInt(ratesecs.totalFnsPoints);
      
    
    let rateIncludingTaxes,
      emeaRegionFlag = false;
    if (rateSeg) {
      if(ratesecs.pacRatePlan || ratesecs.fnsRatePlan){
        $rrc.addClass('points-rate');
        $rrsrb.addClass('points-rate');
      } else {
        $rrc.addClass('cash-rate');
        $rrsrb.addClass('cash-rate');
      }
      if (memberRatePlanList && (memberRatePlanList.indexOf(ratesecs.ratePlanId) > -1)) {
        $rrc.addClass('cug-rate cug-member-rate');
        $rrsrb.addClass('cug-rate cug-member-rate');
        if(CookieHandler.cidAuthenticated()) {
          $('.member-rate.member-rate-text-authenticated', $rrc).show().addClass('show-eyelid');
          $('.member-rate.member-rate-text-authenticated', $rrsrb).show().addClass('show-eyelid');
        } else {
          $('.member-rate.member-rate-text-unauthenticated', $rrc).show().addClass('show-eyelid');
          $('.member-rate.member-rate-text-unauthenticated', $rrsrb).show().addClass('show-eyelid');
        }
      } else if(wrccRateCodes && (wrccRateCodes.indexOf(ratesecs.ratePlanId) > -1)) {
        $rrc.addClass('cug-rate cug-wrcc-rate');
        $rrsrb.addClass('cug-rate cug-wrcc-rate');
        if(CookieHandler.cidAuthenticated() && isWRCCHolder()) {
          $('.member-rate.member-rate-text-wrccmember', $rrc).show().addClass('show-eyelid');
          $('.member-rate.member-rate-text-wrccmember', $rrsrb).show().addClass('show-eyelid');
        }
      } else {
        //WRCCBarclay changes
        if(CookieHandler.cidAuthenticated() && isWRCCHolder() && ratesecs.fnsRatePlan) {
          $rrc.addClass('cug-rate cug-wrcc-rate');
          $rrsrb.addClass('cug-rate cug-wrcc-rate');
          $('.member-rate.member-rate-text-wrccmember', $rrc).show().addClass('show-eyelid');
          $('.member-rate.member-rate-text-wrccmember', $rrsrb).show().addClass('show-eyelid');
        }else{
          $('.member-rate:not(.show-eyelid)', $rrc).remove();
          $('.member-rate:not(.show-eyelid)', $rrsrb).remove();
        }
      }

      if (isMobileWidth() && additionalCorpCodes && ratesecs.corpCode && (additionalCorpCodes.indexOf(ratesecs.corpCode) > -1)) {
        $rrc.addClass('hold-rate');
        $rrc.attr('corpcode', ratesecs.corpCode);
        $rrsrb.addClass('hold-rate');
        $rrsrb.attr('corpcode', ratesecs.corpCode);
      } else if(ratesecs.guranteeCode && hasPolicyFlag(ratesecs.guranteeConfigFlag)) {
        $rrc.addClass('pay-later');
      }
      //Populate the rate level information

      this.currencyCode = ratesecs.currencyCode;
      $rrc.attr('rate', rateSeg.RatePlanCode);
      $rrsrb.attr('rate', rateSeg.RatePlanCode);
      if (rateSeg.RatePlanName) {
        $('.rate-title', $rrc).text(rateSeg.RatePlanName);
        // $('.rate-title', $rrsrb).text(rateSeg.RatePlanName);
      }
      if ((ratesecs.pacRatePlan || ratesecs.fnsRatePlan) && ratesecs.cancelPolicy) {
        $('.description', $rrc).text('Cancellation Policy: ' + ratesecs.cancelPolicy);
        $('.description', $rrsrb).text('Cancellation Policy: ' + ratesecs.cancelPolicy);
      }
      if (rateSeg.RatePlanDescription) {
        $('.description', $rrc).text(rateSeg.RatePlanDescription);
        $('.description', $rrsrb).text(rateSeg.RatePlanDescription);
      }
      if (overview_brandId === 'WY') {
        if (ratesecs.pacRatePlan || ratesecs.fnsRatePlan) {
          $('.description', $rrc).prepend('Wyndham Rewards Free Night Redemption');
          $('.description', $rrsrb).prepend('Wyndham Rewards Free Night Redemption');
        }
      }
        if(isFeesInclude(ratesecs.priceDisplayType)){
          (ratesecs.priceDisplayType == 'CMA') ? $('.pricing .taxes-fees', $rrc).removeClass('hidden') : $('.pricing .fees', $rrc).removeClass('hidden');
          (ratesecs.priceDisplayType == 'CMA') ? $('.pricing .taxes-fees', $rrsrb).removeClass('hidden') : $('.pricing .fees', $rrsrb).removeClass('hidden');
        }

      if (this._emeaRegion && ($('.pricing .taxes-fees span', $rrc).text().trim().length > 0) && (ratesecs.averageAfterTax > 0)) {
        $('.pricing .taxes-fees', $rrc).removeClass('hidden');


        $('.pricing', $rrc).addClass('emea');
        emeaRegionFlag = true;
        rateIncludingTaxes = ratesecs.averageToDisplay;
        if(isFeesInclude(ratesecs.priceDisplayType)){
        rateIncludingTaxes = ratesecs.averageToDisplay;

        }
      } else {
        rateIncludingTaxes = ratesecs.averageToDisplay;
        if(isFeesInclude(ratesecs.priceDisplayType)){
          rateIncludingTaxes = ratesecs.averageToDisplay;

        }
      }
      if (this._emeaRegion && ($('.pricing .taxes-fees span', $rrsrb).text().trim().length > 0) && (ratesecs.averageAfterTax > 0)) {
        $('.pricing .taxes-fees', $rrsrb).removeClass('hidden');


        $('.pricing', $rrsrb).addClass('emea');
        emeaRegionFlag = true;
        rateIncludingTaxes = ratesecs.averageToDisplay;
        if(isFeesInclude(ratesecs.priceDisplayType)){
        rateIncludingTaxes = ratesecs.averageToDisplay;

        }
      } else {
        rateIncludingTaxes = ratesecs.averageToDisplay;
        if(isFeesInclude(ratesecs.priceDisplayType)){
          rateIncludingTaxes = ratesecs.averageToDisplay;

        }
      }
      getCurrencyMapping(ratesecs.currencyCode, false);
      if (ratesecs.fnsRatePlan) {
        $('.money', $rrc).remove();
        $('.points', $rrc).show();
        $('.money', $rrsrb).remove();
        $('.points', $rrsrb).show();
        // Show by-rooms, by points "Book" text
        $('.points .from', $rrc).css('display', 'inline-flex');
        $('.points .from', $rrsrb).css('display', 'inline-flex');
        //WRCCBarclay changes
        if(CookieHandler.cidAuthenticated() && isWRCCHolder()){
          $rrc.addClass('striked-rate cug-rate cug-wrcc-rate');
          $rrsrb.addClass('striked-rate cug-rate cug-wrcc-rate');
          //$('.points .rate', $rrc).html(formatNumber(parseInt(ratesecs.fnsPoints)) + '<span class="strike-element"></span>');
          $('.points .rate', $rrc).html('<strike>' + formatNumber(parseInt(ratesecs.fnsPoints)) + '</strike>');
          $('.points .rate', $rrsrb).html('<strike>' + formatNumber(parseInt(ratesecs.fnsPoints)) + '</strike>');
        }else{
          $('.points .rate', $rrc).text(formatNumber(parseInt(ratesecs.fnsPoints)));
          $('.points .rate', $rrsrb).text(formatNumber(parseInt(ratesecs.fnsPoints)));
        }

        if(!this.minimumPoint || this.minimumPoint > parseInt(ratesecs.fnsPoints)){
          this.minimumPoint = parseInt(ratesecs.fnsPoints);
        }
        room.isSRBMatch = true;
      } else if (ratesecs.pacRatePlan) {
        if (rateIncludingTaxes === 0) {
          return;
        }
        let moneySplit = toMoney(convertToCurrency(rateIncludingTaxes,ratesecs.currencyCode)).split('.');
        $('.money .rate', $rrc).html((getSelectedCurencyCode(ratesecs.currencyCode) == 'USD' ? '$' : '') + moneySplit[0] +
          '<div class="superscript">' + moneySplit[1] + '</div> <span class="strike-element"></span>');
        $('.unit-per-night .unit', $rrc).text(getSelectedCurencyCode(ratesecs.currencyCode));
        $('.money .plus-points', $rrc).text('+ ' + formatNumber(ratesecs.pacPoints));
        $('.plus-points, .pts-label', $rrc).show();

        $('.money .rate', $rrsrb).html(
          '<div class="rate-value headline-h">+ ' + 
          (getSelectedCurencyCode(ratesecs.currencyCode) === 'USD' ? '$' : '') + 
          moneySplit[0] + 
          '</div>' +
          '<div class="superscript">' + moneySplit[1] + '</div>' +
          '<span class="strike-element"></span>'
      );

        // $('.money .rate',$rrsrb).html('+ ' + (getSelectedCurencyCode(ratesecs.currencyCode) == 'USD' ? '$' : '') + moneySplit[0] +
        //   '<div class="superscript">' + moneySplit[1] + '</div> <span class="strike-element"></span>');
        $('.unit-per-night .unit',$rrsrb).text(getSelectedCurencyCode(ratesecs.currencyCode));
        $('.money .plus-points', $rrsrb).text(formatNumber(ratesecs.pacPoints));
        $('.plus-points, .pts-label', $rrsrb).show();

        if(!this.minimumPoint || this.minimumPoint > parseInt(ratesecs.pacPoints)){
          this.minimumPoint = parseInt(ratesecs.pacPoints);
        }


        if(!BNPLUplift.isRatePlanDisabled(ratesecs.ratePlanId)){

          if (ratesecs.currencyCode === 'USD') {
            $('.wyn-uplift-content .wyn-uplift-currency-symbol', $rrc).append('$');
            $('.wyn-uplift-content .wyn-uplift-currencyCode', $rrc).append('\u00A0' + ratesecs.currencyCode);
            $('.wyn-uplift-content .wyn-uplift-currency-symbol', $rrsrb).append('$');
            $('.wyn-uplift-content .wyn-uplift-currencyCode', $rrsrb).append('\u00A0' + ratesecs.currencyCode);
          } else {
            $('.wyn-uplift-content .wyn-uplift-currencyCode', $rrc).append('\u00A0' + ratesecs.currencyCode);
            $('.wyn-uplift-content .wyn-uplift-currencyCode', $rrsrb).append('\u00A0' + ratesecs.currencyCode);
          }

          //let roomCode = room.roomTypeCode;
          let roomName = room.shortName;
          let checkIn = this._params.checkInDate.split('-');
          let checkInFormat = checkIn[2] + checkIn[0] + checkIn[1];

          $('.wyn-uplift-content .wyn-uplift-promo', $rrc).attr({
            'data-up-details-hotel_reservations-0-property_code': overview_propertyId,
            'data-up-details-hotel_reservations-0-room_type': roomName,
            'data-up-details-hotel_reservations-0-check_in': checkInFormat,
            'data-up-details-hotel_reservations-0-rate_code': ratesecs.ratePlanId,
            'data-up-details-hotel_reservations-0-reservation_type': BNPLUplift.isPrepaidCodes(ratesecs.guranteeCode)

          });

          $('.wyn-uplift-content .wyn-uplift-promo', $rrsrb).attr({
            'data-up-details-hotel_reservations-0-property_code': overview_propertyId,
            'data-up-details-hotel_reservations-0-room_type': roomName,
            'data-up-details-hotel_reservations-0-check_in': checkInFormat,
            'data-up-details-hotel_reservations-0-rate_code': ratesecs.ratePlanId,
            'data-up-details-hotel_reservations-0-reservation_type': BNPLUplift.isPrepaidCodes(ratesecs.guranteeCode)

          });

          $('.wyn-uplift-content .wyn-uplift-price', $rrc).attr('data-up-price-value', (moneySplit[0] + moneySplit[1]));
          $('.wyn-uplift-content .wyn-uplift-price', $rrsrb).attr('data-up-price-value', (moneySplit[0] + moneySplit[1]));
          //$('.wyn-uplift-content .wyn-uplift-promo', $rrc).attr('data-up-details-hotel_reservations-0-property_code', roomCode);
          //$('.wyn-uplift-content .wyn-uplift-promo', $rrc).attr('data-up-details-hotel_reservations-0-room_type', roomName);
          //$('.wyn-uplift-content .wyn-uplift-promo', $rrc).attr('data-up-details-hotel_reservations-0-check_in', checkInFormat);
          //$('.wyn-uplift-content .wyn-uplift-promo', $rrc).attr('data-up-details-hotel_reservations-0-rate_code', ratesecs.ratePlanId);

        }else{
          $('.wyn-uplift-content', $rrc).remove();
          $('.wyn-uplift-content', $rrsrb).remove();
        }

      } else {
        // filter $0 rates
        if (rateIncludingTaxes === 0) {
          return;
        }
        if (ratesecs.cugRate && !UserHandler.isWRLoggedIn()) {
          let barRate = room.rates.filter((el) => {
            return isBarRate(el.ratePlanId);
          })[0];
          let nextLowRate = (barRate ? (emeaRegionFlag ? barRate.averageAfterTax : barRate.averageBeforeTax) : rateIncludingTaxes);
          let unroundedPercent = (1 - (parseFloat(rateIncludingTaxes) / parseFloat(nextLowRate))) * 100;
          // Handle for floating point errors; round down in all cases except if the number should be a round integer.
          let percentOff = (unroundedPercent % 1 > 0.999) ? Math.round(unroundedPercent) : Math.floor(unroundedPercent);
          $('.cug-action', $rrc).show();
          $('.cug-action', $rrsrb).show();
          $('.cug-action .percent-off', $rrc).html(percentOff + '% OFF');
          $('.cug-action .percent-off', $rrsrb).html(percentOff + '% OFF');
          let moneySplit = toMoney(convertToCurrency(rateIncludingTaxes,ratesecs.currencyCode)).split('.');
          $('.money .rate', $rrc).html((getSelectedCurencyCode(ratesecs.currencyCode) == 'USD' ? '$' : '') + moneySplit[0] +
            '<div class="superscript">' + moneySplit[1] + '</div> <span class="strike-element"></span>');
          $('.money .rate', $rrsrb).html((getSelectedCurencyCode(ratesecs.currencyCode) == 'USD' ? '$' : '') + moneySplit[0] +
          '<div class="superscript">' + moneySplit[1] + '</div> <span class="strike-element"></span>');
          $('.unit-per-night .unit', $rrc).text(getSelectedCurencyCode(ratesecs.currencyCode));
          $('.unit-per-night .unit', $rrsrb).text(getSelectedCurencyCode(ratesecs.currencyCode));
        } else {
          let moneySplit = toMoney(convertToCurrency(rateIncludingTaxes,ratesecs.currencyCode)).split('.');
          $('.money .rate', $rrc).html((getSelectedCurencyCode(ratesecs.currencyCode) == 'USD' ? '$' : '') + moneySplit[0] +
            '<div class="superscript">' + moneySplit[1] + '</div> <span class="strike-element"></span>');
          $('.money .rate', $rrsrb).html((getSelectedCurencyCode(ratesecs.currencyCode) == 'USD' ? '$' : '') + moneySplit[0] +
          '<div class="superscript">' + moneySplit[1] + '</div> <span class="strike-element"></span>');
          $('.unit-per-night .unit', $rrc).text(getSelectedCurencyCode(ratesecs.currencyCode));
          $('.unit-per-night .unit', $rrsrb).text(getSelectedCurencyCode(ratesecs.currencyCode));
          room.isRateMatch = true;
        }

        if(!BNPLUplift.isRatePlanDisabled(ratesecs.ratePlanId)){
          //let roomCode = room.roomTypeCode;
          let roomName = room.shortName;
          let checkIn = this._params.checkInDate.split('-');
          let checkInFormat = checkIn[2] + checkIn[0] + checkIn[1];

          // Add price to uplift data attribute
          let roomRate = rateIncludingTaxes.toString();
          let upliftRate;

          if (roomRate.includes('.')) {
            var roomSplitRate = roomRate.split('.');

            if(roomSplitRate[1].length == 1) {
              roomSplitRate[1] += '0';
            }

            upliftRate = roomSplitRate[0] + roomSplitRate[1];

          } else {
            upliftRate = roomRate + '00';
          }

          if (ratesecs.currencyCode === 'USD') {
            $('.wyn-uplift-content .wyn-uplift-currency-symbol', $rrc).append('$');
            $('.wyn-uplift-content .wyn-uplift-currencyCode', $rrc).append('\u00A0' + ratesecs.currencyCode);
            $('.wyn-uplift-content .wyn-uplift-currency-symbol', $rrsrb).append('$');
            $('.wyn-uplift-content .wyn-uplift-currencyCode', $rrsrb).append('\u00A0' + ratesecs.currencyCode);
          } else {
            $('.wyn-uplift-content .wyn-uplift-currencyCode', $rrc).append('\u00A0' + ratesecs.currencyCode);
            $('.wyn-uplift-content .wyn-uplift-currencyCode', $rrsrb).append('\u00A0' + ratesecs.currencyCode);
          }

          $('.wyn-uplift-content .wyn-uplift-price', $rrc).attr('data-up-price-value', upliftRate);
          $('.wyn-uplift-content .wyn-uplift-price', $rrsrb).attr('data-up-price-value', upliftRate);

          $('.wyn-uplift-content .wyn-uplift-price', $rrc).attr({
            'data-up-details-hotel_reservations-0-property_code': overview_propertyId,
            'data-up-details-hotel_reservations-0-room_type': roomName,
            'data-up-details-hotel_reservations-0-check_in': checkInFormat,
            'data-up-details-hotel_reservations-0-rate_code': ratesecs.ratePlanId,
            'data-up-details-hotel_reservations-0-reservation_type': BNPLUplift.isPrepaidCodes(ratesecs.guranteeCode)
          });

          $('.wyn-uplift-content .wyn-uplift-price', $rrsrb).attr({
            'data-up-details-hotel_reservations-0-property_code': overview_propertyId,
            'data-up-details-hotel_reservations-0-room_type': roomName,
            'data-up-details-hotel_reservations-0-check_in': checkInFormat,
            'data-up-details-hotel_reservations-0-rate_code': ratesecs.ratePlanId,
            'data-up-details-hotel_reservations-0-reservation_type': BNPLUplift.isPrepaidCodes(ratesecs.guranteeCode)
          });

        }else{
          $('.wyn-uplift-content', $rrc).remove();
          $('.wyn-uplift-content', $rrsrb).remove();
        }

      }

    }

    //Reserve button binding activate
    $('.book-now', $rrc).on('click', async(e) => {
      if (getCriteria().rooms > 1 && $('#wyndham-rewards-filter').is(':checked')) {
        simpleLightbox($('#numOfRooms').data('rewards-error'), () => {
          let $form = $('form.booking-bar-form.mini-booking.clearfix');
          BBForm.bbRoomsGuests.setForm($form);
          BBForm.bbRoomsGuests.open();
          BBForm.bbRoomsGuests.errorRooms(true);
        });
        return;
      }
      Analytics.updateRoomsRates(e.currentTarget);

      let rImg = $(e.currentTarget).parents('.room')
        .find('img')
        .eq(0)
        .attr('src');
      if (ratesecs.pacRatePlan || ratesecs.fnsRatePlan) {
        if (UserHandler.isWRLoggedIn()) {
          let userPts = UserHandler.getLimitedProfile().pointBal;
          if ((ratesecs.fnsRatePlan && userPts < parseInt(totPoints)) || (ratesecs.pacRatePlan &&
              ratesecs.totalPacPoints && userPts < parseInt(ratesecs.totalPacPoints))) {
            if (typeof window.notEnoughPointsMessage !== 'undefined') {
              simpleLightbox(window.notEnoughPointsMessage || 'Additional points are required to reserve this room.');
            } else {
              simpleLightbox('Additional points are required to reserve this room.');
            }
          } else {
            //submit the form to booking page
            this.triggerReserve(room.roomTypeCode, ratesecs.ratePlanId, ratesecs.corpCode, ratesecs.autoEnroll, ratesecs.qualPointsEarned, ratesecs.pacRatePlan, ratesecs.totalAfterTax, rImg,ratesecs.currencyCode);
          }
        } else {
          this.promptLogin();
        }
      } else if (ratesecs.cugRate && !UserHandler.isWRLoggedIn()) {
        this.promptLogin();
      } else {
        //submit the form to booking page
        this.triggerReserve(room.roomTypeCode, ratesecs.ratePlanId, ratesecs.corpCode, ratesecs.autoEnroll, ratesecs.qualPointsEarned, ratesecs.pacRatePlan, ratesecs.totalAfterTax, rImg,ratesecs.currencyCode);
      }
      return false;
    });
    $('.book-now', $rrsrb).on('click', async(e) => {
      if (getCriteria().rooms > 1 && $('#wyndham-rewards-filter').is(':checked')) {
        simpleLightbox($('#numOfRooms').data('rewards-error'), () => {
          let $form = $('form.booking-bar-form.mini-booking.clearfix');
          BBForm.bbRoomsGuests.setForm($form);
          BBForm.bbRoomsGuests.open();
          BBForm.bbRoomsGuests.errorRooms(true);
        });
        return;
      }
      Analytics.updateRoomsRates(e.currentTarget);

      let rImg = $(e.currentTarget).parents('.room')
        .find('img')
        .eq(0)
        .attr('src');
      if (ratesecs.pacRatePlan || ratesecs.fnsRatePlan) {
        if (UserHandler.isWRLoggedIn()) {
          let userPts = UserHandler.getLimitedProfile().pointBal;
          if ((ratesecs.fnsRatePlan && userPts < parseInt(totPoints)) || (ratesecs.pacRatePlan &&
              ratesecs.totalPacPoints && userPts < parseInt(ratesecs.totalPacPoints))) {
            if (typeof window.notEnoughPointsMessage !== 'undefined') {
              simpleLightbox(window.notEnoughPointsMessage || 'Additional points are required to reserve this room.');
            } else {
              simpleLightbox('Additional points are required to reserve this room.');
            }
          } else {
            //submit the form to booking page
            this.triggerReserve(room.roomTypeCode, ratesecs.ratePlanId, ratesecs.corpCode, ratesecs.autoEnroll, ratesecs.qualPointsEarned, ratesecs.pacRatePlan, ratesecs.totalAfterTax, rImg,ratesecs.currencyCode);
          }
        } else {
          this.promptLogin();
        }
      } else if (ratesecs.cugRate && !UserHandler.isWRLoggedIn()) {
        this.promptLogin();
      } else {
        //submit the form to booking page
        this.triggerReserve(room.roomTypeCode, ratesecs.ratePlanId, ratesecs.corpCode, ratesecs.autoEnroll, ratesecs.qualPointsEarned, ratesecs.pacRatePlan, ratesecs.totalAfterTax, rImg,ratesecs.currencyCode);
      }
      return false;
    });
    $rrc.show();
    $rrsrb.show();
    $('#general-rate-list .see-more', $rm).before($rrc);
    if(['SRB6','SRB7','SRB8'].includes(ratesecs.ratePlanId)){
      $('#srb-rate-list .see-more', $rm).before($rrsrb);
    }

    $('.stay-total', $rrc).on('click', (e) => {
      e.preventDefault();

      this.displayRoomTaxInfo(ratesecs, $rrc);
      this.displayTaxModal();

      // if (!($_PAGE_.is('.modify-page'))) {
      //   this.displayRoomTaxInfo(ratesecs, $rrc);
      // } else {
      //   this.populateRateDetails(
      //     $(e.currentTarget),
      //     (roomCode) => this.getRoom(roomCode),
      //     (rateCode, room) => this.getRate(rateCode, room));

      // }

      return false;
    });

    $('.stay-total', $rrsrb).on('click', (e) => {
      e.preventDefault();

      this.displayRoomTaxInfo(ratesecs, $rrsrb);
      this.displayTaxModal();

      // if (!($_PAGE_.is('.modify-page'))) {
      //   this.displayRoomTaxInfo(ratesecs, $rrc);
      // } else {
      //   this.populateRateDetails(
      //     $(e.currentTarget),
      //     (roomCode) => this.getRoom(roomCode),
      //     (rateCode, room) => this.getRate(rateCode, room));

      // }

      return false;
    });

    $('.addl-details', $rrc).on('click', () => {
      let msg = '<div class="addl-details-modal">';
      msg += '<h4 class="modal-title headline-e">' + $('.addl-details', $rrc).data('modal-title') + '</h4>';
      msg += '<h5 class="headline-e">' + $('.addl-details', $rrc).data('rate-details-section-title') + '</h5>';
      msg += '<p>' + rateSeg.RatePlanDescription + '</p>';
      msg += '<h5 class="headline-e">' + $('.addl-details', $rrc).data('cancel-policy-section-title') + '</h5>';
      if ((ratesecs.depositPolicy != '') && (ratesecs.depositPolicy != null) && (ratesecs.depositPolicy != undefined) &&
        (ratesecs.depositPolicy != 'null')) {
        msg += '<p>' + ratesecs.cancelPolicy + '<br/><br/>' + ratesecs.depositPolicy + '</p>';
      } else {
        msg += '<p>' + ratesecs.cancelPolicy + '</p>';
      }
      msg += '</div>';
      this.showLightbox(msg);
      return false;
    });

    $('.addl-details', $rrsrb).on('click', () => {
      let msg = '<div class="addl-details-modal">';
      msg += '<h4 class="modal-title headline-e">' + $('.addl-details', $rrsrb).data('modal-title') + '</h4>';
      msg += '<h5 class="headline-e">' + $('.addl-details', $rrsrb).data('rate-details-section-title') + '</h5>';
      msg += '<p>' + rateSeg.RatePlanDescription + '</p>';
      msg += '<h5 class="headline-e">' + $('.addl-details', $rrsrb).data('cancel-policy-section-title') + '</h5>';
      if ((ratesecs.depositPolicy != '') && (ratesecs.depositPolicy != null) && (ratesecs.depositPolicy != undefined) &&
        (ratesecs.depositPolicy != 'null')) {
        msg += '<p>' + ratesecs.cancelPolicy + '<br/><br/>' + ratesecs.depositPolicy + '</p>';
      } else {
        msg += '<p>' + ratesecs.cancelPolicy + '</p>';
      }
      msg += '</div>';
      this.showLightbox(msg);
      return false;
    });

    if (ratesecs.depositPolicy) {
      $rm.data('res-policy', ratesecs.cancelPolicy + ' </br></br>' + ratesecs.depositPolicy);
    } else {
      $rm.data('res-policy', ratesecs.cancelPolicy);
    }
  }
  // Get Packages
  fetchPackages(rt, rp, cc, ae, qp, pac, at, rImg,isModify,directBillSupported,currencyCode) {
    let data = {
      "checkin_date": this._params.checkInDate,
      "checkout_date": this._params.checkOutDate,
      "rate_plan": rp,
      "room_type_code": rt,
      "propertyId": this._params.propertyId,
      "adults": this._params.adults,
      "children":this._params.children,
      "childAge":this._params.childAge,
      "language":_LOCALE_,
      "noOfRooms":this._params.rooms,
      "requested_currency_code":currencyCode
    };

    if(data && _isNotNull(data.propertyId) && data.propertyId !== ''){
      callService('getPackages', $.param(data), 'GET').then((res) => {
        if (res && res.status === 'OK' || res.services && res.services.length > 0){
          let isCategoryHasPackage = false;
          this.updateAlreadyAddedPackage(res.services);
          $.each(this.addonsCategories[0],(counter,category)=>{
            isCategoryHasPackage = isCategoryHasPackage || res.services.reduce((result , item) => {
              return item && item.comments && item.comments.category === JSON.parse(category)[_LOCALE_] ? true : result;
            },null);
          });
          if(isCategoryHasPackage){
            goToPackages(rt, rp, cc, ae, qp, pac, at, rImg, isModify, directBillSupported);
          }else{
            goToBook(rt, rp, cc, ae, qp, pac, at, rImg, isModify, directBillSupported);
          }
        }else{
          goToBook(rt, rp, cc, ae, qp, pac, at, rImg, isModify, directBillSupported);
        }
      });
    }else{
      goToBook(rt, rp, cc, ae, qp, pac, at, rImg, isModify, directBillSupported);
    }
  }

  updateAlreadyAddedPackage(addonPackages){
    let addedPackages = getSessionStorage('added-package') || {};
    $.each(addonPackages , (counter , packageAddons)=> {
      if(addedPackages[this.slugify(packageAddons.comments.title)]){
        addedPackages[this.slugify(packageAddons.comments.title)].packageAddons =  packageAddons;
      }
    });
    setSessionStorage('added-package',addedPackages,true);
  }

  slugify(str){
    return str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }

  // Populate Rate/Room details from RnRListing on Modify page ONLY
  populateRateDetails($el, getRoom, getRate) {
    let data = ModifySession.get();

    let roomDetailCode = $el.parents('.room').attr('room'),
      rateDetailCode = $el.parents('.rate').attr('rate'),
      room = getRoom(roomDetailCode),
      rate = getRate(rateDetailCode, room);

    data.taxList = rate.taxList;
    data.currencyCode = rate.currencyCode;
    data.averageBeforeTax = rate.averageBeforeTax;
    data.averageAfterTax = rate.averageAfterTax;
    data.averageToDisplay = rate.averageToDisplay;
    data.totalBeforeTax = rate.totalBeforeTax;
    data.totalAfterTax = rate.totalAfterTax;
    data.taxTotal = parseFloat(rate.totalAfterTax - rate.totalBeforeTax).toFixed(2);
    let addedPackages = getSessionStorage('added-package') || {};
    $.each(addedPackages, (counter, addedpackage)=>{
      data.totalAfterTax += (this.totalPrice(addedpackage.packageAddons,addedpackage.price.amountAfterTax) * addedpackage.qty * this.criteria.rooms);
      data.totalAverageToDisplay += (this.totalPrice(addedpackage.packageAddons,addedpackage.price.amountAfterTax) * addedpackage.qty * this.criteria.rooms);
    });
    RateDetails.handleAll(data);
  }

  strikeThroughRates() {
    let $rooms = $('.rooms-rates-listing .room:not(.clone)'),
      $rates,
      $rate,
      $memberRates,
      memberRatesArray,
      $barRate;

    //User unauthenticated and StrikeThrough flag is enabled
    if (isStrikeThroughRateEnable(this._data.overview) && !UserHandler.isWRLoggedIn()) {
      // find member and BAR rates in each room
      $rooms.each((i, roomEl) => {
        $rates = $(roomEl).find('li.rate');
        $barRate = '';
        memberRatesArray = [];

        $rates.each((j, rateEl) => {
          $rate = $(rateEl);
          if ($rate.attr('rate')) {
            if (isBarRate($rate.attr('rate'))) {
              $barRate = $rate;
            } else if (isMemberRate($rate.attr('rate'))) {
              memberRatesArray.push($rate);
            }
          }
        });

        // replace any member rates w/bar rate and apply strike-through style
        if ($barRate.length && memberRatesArray.length) {
          $memberRates = $(memberRatesArray).map((i, el) => {
            return el.toArray();
          });
          $memberRates.find('.room-rate .rate').html($barRate.find('.room-rate .rate').html());
          $memberRates.addClass('striked-rate');

          let strikeThroughRates = $memberRates.find('.room-rate .rate').html($barRate.find('.room-rate .rate').html());
          let strikeThroughRate = strikeThroughRates.text().split(' ');

          //Strike through rate for uplift
          $memberRates.find('.wyn-uplift-content .wyn-uplift-price').attr('data-up-price-value', strikeThroughRate[0].replace('$', ''));
        }
      });
    }
  }

  // controls the toggling and checks for the hidden rooms and rates. Appends button to bottom of list
  seeMoreLess(hiddenEls, toggleBtn) {
    let slides = $('div.listing').children('div'),
      roomSlideLevelSeeMoreLess = $('.room-slide-level');

    if (slides.hasClass('rooms-slide') && slides.filter('.rooms-slide').children()
      .not('.clone')
      .filter(
        '[visible-index]').length > 3) {
      let roomsSlide = slides.filter('.rooms-slide');
      $(roomsSlide).append(roomSlideLevelSeeMoreLess);
      $(roomSlideLevelSeeMoreLess).show();
    }

    $(toggleBtn).on('click', (e) => {
      e.preventDefault();
      let topPos = e.currentTarget.getBoundingClientRect().top;
      $(hiddenEls).toggleClass('hidden-after-three');
      $(e.currentTarget).find('.slide-see-moreless')
        .toggleClass('lessMore');
      scrollTo(0, $(e.currentTarget).offset().top - topPos);
      Analytics.updateRoomsData();
      Analytics.updateRatesData();
      return false;
    });
  }

  getRoom(roomCode) {
    let rooms = this._data.roomsAndRates.rooms;
    if (rooms && rooms.length > 0) {
      for (let itr = 0; itr < rooms.length; itr++) {
        if (rooms[itr].roomTypeCode == roomCode) {
          return rooms[itr];
        }
      }
    }
  }

  getRate(rateCode, room) {
    let rates = !($.isEmptyObject(room)) ? room.rates : this._data.roomsAndRates.ratePlans;
    var ratePlans = this._data.roomsAndRates.ratePlans;
    if (rates && rates.length > 0) {
      for (let itr = 0; itr < rates.length; itr++) {
        if (rates[itr].RatePlanCode == rateCode || rates[itr].ratePlanId == rateCode) {
          if(!rates[itr].ratePlanName && ratePlans && ratePlans.length){
             for (var itrPln = 0; itrPln < ratePlans.length; itrPln++) {
               if (ratePlans[itrPln].RatePlanCode == rateCode || ratePlans[itrPln].ratePlanId == rateCode) {
                  rates[itr].ratePlanName = ratePlans[itrPln].RatePlanName;
                  return rates[itr];
               }
             }
          } else {
            return rates[itr];
          }
        }
      }
    }
  }

  displayRoomTaxInfo(ratesec, $el) {
    this.populateRoomRateTax($el.closest('.rate'), ratesec, this.getRate(ratesec.ratePlanId));
    this.populateBreakDown(ratesec);
    this.displayTaxModal();

    return false;
  }

  populateRoomRateTax($ctxt, ratesecs, rateSeg) {
    let pdata = this._data.overview,
      pol = {},
      checkInDate = getDateFromDateString(this._params.checkInDate),
      checkOutDate = getDateFromDateString(this._params.checkOutDate);
    //populate the values
    $.each(pdata.hotelPolicy, (counter, poli) => {
      if ((poli).replace(/\s/g, '-').toLowerCase()
        .match(/check-in-time/)) {
        pol.checkInDate = poli.split('#')[1];
      }
      if ((poli).replace(/\s/g, '-').toLowerCase()
        .match(/check-out-time/)) {
        pol.check_out = poli.split('#')[1];
      }
    });
    pol.checkInDate = (pol.checkInDate) ? pol.checkInDate : '';
    pol.check_out = (pol.check_out) ? pol.check_out : '';

    setCriteria({
      nights: getNumDays(checkInDate, checkOutDate)
    });

    BookingSummary.handleCheckIn(checkInDate, pol.checkInDate);
    BookingSummary.handleCheckOut(checkOutDate, pol.check_out);
    BookingSummary.handleTotalTax((ratesecs.totalAfterTax - ratesecs.totalBeforeTax), ratesecs.currencyCode);

    if (!($_PAGE_.is('.modify-page'))) {

      BookingSummary.handleAdults(this._params.adults);
      BookingSummary.handleChildren(this._params.children);
      BookingSummary.handleRooms(this._params.rooms);
      BookingSummary.handleNights(getCriteria().nights);
    }

    if (ratesecs.depositPolicy) {
      BookingSummary.handleCancelPolicy(ratesecs.cancelPolicy + '</br></br>' + ratesecs.depositPolicy);
    } else {
      BookingSummary.handleCancelPolicy(ratesecs.cancelPolicy);
    }
    $('.modal-section.tax-info div').not('.estimated-taxes')
      .remove();

    RateDetails.fillTaxes(ratesecs.taxList, ratesecs.currencyCode, null, getBrand(true), ratesecs);
    if (ratesecs.fnsRatePlan) {
      let fees = 0,
        currency;
      if (ratesecs.taxList[0]) {
        fees = parseFloat(ratesecs.taxList[0] ? ratesecs.taxList[0].taxPercent : 0);
        fees *= getCriteria().nights;
        currency = ratesecs.taxList[0].taxPercent.replace(/[0-9\W]/g, '');
      }
      RateDetails.handleGoFree(formatNumber(parseInt(ratesecs.totalFnsPoints)), fees, currency);
    } else {
     // RateDetails.fillTotal(ratesecs.totalBeforeTax, ratesecs.totalAfterTax, ratesecs.currencyCode, ratesecs.pacPoints * getCriteria().nights);
    }
  }

  populateBreakDown(ratesec) {
    console.log({ratesec});
    let dateRateMapObj = ratesec.dateRateMap,
      currencyCode = ratesec.currencyCode,
      $brkdwnheader = $('.rate-summary-lightbox').find('.rate-breakdown .room-nights-header'),
      $brkdwnvalue = $('.rate-summary-lightbox').find('.rate-breakdown .room-nights-value');
    $brkdwnheader.find('li:not(.breakdown-label)').remove();
    $brkdwnvalue.find('li:not(.breakdown-label)').remove();
    for (let propName in dateRateMapObj) {
      let propValue = dateRateMapObj[propName];
      $brkdwnheader.append(`<li class='' title='weekdaycompact'>${formatDateForPrinting(propName, 'weekdayCompact')}</li>`);
      $brkdwnvalue.append(`<li class="dollar-amount">${printDollarAmount(parseFloat(propValue), currencyCode, true)}</li>`);
    }

  }

  displayTaxModal() {
    $('#rateSummaryDetail').modal('show');
    if (isMobileWidth()) {
      $('#rateSummaryDetail').height(getWindowHeight())
        .css({
          'top': 0,
          'position': 'fixed',
          'margin-top': 0
        });
    }
  }

  async promptLogin() {
    bookDataBeforeSSOLogin();
    Login.redirectToOktaLogin(window.location.href);
  }

  triggerReserve(rt, rp, cc, ae, qp, pac, at, rImg,currencyCode) {
    if (!($_PAGE_.is('.modify-page'))) {
      if(this.ratePlanSkipPackage.includes(rp) || this._params.rooms > 1){
        setSessionStorage('added-package',{},true);
        goToBook(rt, rp, cc, ae, qp, pac, at, rImg, false, this._data.overview.directBillSupported || false);
      }else{
        this.fetchPackages(rt, rp, cc, ae, qp, pac, at, rImg, false, this._data.overview.directBillSupported || false,currencyCode);
      }
    } else {
      let currentRoom = this.getRoom(rt),
        currentRate = this.getRate(rp, currentRoom);

      ModifySession.set($.extend(currentRoom, currentRate));

      EventHandler.send('modify:next');
    }
  }
}

function initRoomsRatesListingComponent() {
  let toggleFiltersFirstTime = true;

  $('.show-hide-filter, .filter-close-button').click(() => {
    if (toggleFiltersFirstTime) {
      toggleFiltersFirstTime = false;
      $('.refine-result-container .panel-collapse.in').collapse('hide');
    }
    $('.refine-result-wrapper').slideToggle(150);
  });
}


if($_PAGE_.is('.modify-page')) {

  EventHandler.one('modify-view-changed-to-select', () => {
    // If hidden from no availablility, show when reopened
    if ($('.rooms-rates-listing-container').is(':hidden')) {
      $('.rooms-rates-listing-container').show();
    }
    $('.rooms-rates-listing-container').find('.currency-toggle__booking').hide();

    $('.refine-result-container .wyndhamrewardsfilter').remove();
    initRoomsRatesListingComponent();

    let rrInst = new RoomsRates();

    EventHandler.on('modify-view-changed-to-change', () => {
      $('.rooms-slide > .room:not( .clone )').remove();
      $('#bedTypeFilter,#roomTypeFilter').find('li:not( .clone )')
        .remove();

      $('.slide-level-see-more').hide();

      EventHandler.one('modify-view-changed-to-select', () => {
        if ($('.rooms-rates-listing-container').is(':hidden')) {
          $('.no-rates-available.no-rates-available-header-container').hide();
          $('.rooms-rates-listing-container').show();
        }

        rrInst.initParams();
        rrInst.fetchRoomsRates();
      });
    });
  });
} else if($_PAGE_.is('.rooms-rates-page')) {
  initRoomsRatesListingComponent();
  new RoomsRates();
}

